var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.changeEntry)}}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Edit Entry")])]),_c('section',{staticClass:"modal-card-body"},[_c('b-field',{attrs:{"label":"Name"}},[_c('b-input',{attrs:{"type":"text","value":_vm.user,"required":"","disabled":"","icon":"user"}})],1),_c('b-field',{attrs:{"label":"Kunde"}},[_c('b-input',{attrs:{"type":"text","value":_vm.customer,"required":"","disabled":"","icon":"building"}})],1),_c('ValidationProvider',{attrs:{"name":"area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Bereich ändern","type":{'is-danger': errors[0] && _vm.customer, 'is-success': valid, 'is-unselectable': !_vm.customer},"message":_vm.customer?errors:''}},[_c('b-autocomplete',{key:"bereich",attrs:{"expanded":"","disabled":!_vm.customer,"open-on-focus":"","data":_vm.filteredBereicheArray,"placeholder":"Choose Area","icon":"folder-open"},on:{"select":function (option) { return _vm.selected = option; }},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}},[_c('template',{slot:"empty"},[_vm._v("No Area named: \""+_vm._s(_vm.area)+"\"")])],2)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"job","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Job ändern","type":{'is-danger': errors[0] && _vm.customer, 'is-success': valid},"message":_vm.customer?errors:''}},[_c('b-autocomplete',{key:"job",attrs:{"expanded":"","disabled":!_vm.customer,"open-on-focus":"","data":_vm.filteredJobsArray,"placeholder":"Choose Job","icon":"file-alt"},on:{"select":function (option) { return _vm.selected = option; }},model:{value:(_vm.job),callback:function ($$v) {_vm.job=$$v},expression:"job"}},[_c('template',{slot:"empty"},[_vm._v("No Job named: \""+_vm._s(_vm.job)+"\"")])],2)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Change date","type":{'is-danger': errors[0], 'is-success': valid},"message":'Original: ' + _vm.dateToHuman(_vm.$attrs.selected.date)}},[_c('b-datepicker',{key:"date",staticClass:"is-small",attrs:{"placeholder":"Click to select...","icon":"calendar","expanded":"","max-date":_vm.maxDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('div',{staticClass:"buttons is-right"},[_c('button',{staticClass:"button is-primary is-fullwidth",on:{"click":function($event){$event.preventDefault();_vm.date = _vm.maxDate}}},[_c('b-icon',{attrs:{"icon":"calendar-alt"}}),_c('span',[_vm._v("Today")])],1)])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"duration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var errors = ref.errors;
var valid = ref.valid;return [_c('b-field',{attrs:{"label":"Zeitspanne eingeben","type":{'is-danger':errors[0], 'is-success': valid},"message":( _obj = {}, _obj[errors] = errors[0], _obj['Im Format:  01h 05m'] =  !errors[0], _obj )}},[_c('b-input',{directives:[{name:"cleave",rawName:"v-cleave",value:(_vm.masks.duration),expression:"masks.duration"}],key:"duration",staticClass:"duration",attrs:{"type":"text","value":_vm._f("durationFilter")(_vm.duration),"required":"","icon":"clock"},nativeOn:{"keyup":function($event){return _vm.onInput($event)}}})],1)]}}],null,true)}),_c('b-field',{attrs:{"label":"Notiz"}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1),_c('footer',{staticClass:"modal-card-foot level"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('button',{ref:"cancel",staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Cancel")])]),_c('div',{staticClass:"level-item"},[_c('button',{staticClass:"button is-primary"},[_vm._v("Submit Changes")])])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('button',{staticClass:"button is-danger",on:{"click":function($event){$event.preventDefault();return _vm.deleteEntry()}}},[_vm._v("Delete")])])])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }